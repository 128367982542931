import React, { useState } from "react"
import PropTypes from "prop-types"

const OfficeItem = ({
  office: { locationName, phone, address },
  isActive,
  isLast,
  setOfficeIndex,
  index,
}) => {
  const activeClass = isActive
    ? "sm:text-orange-400 sm:border-orange-400"
    : "sm:hover:text-gray-700 sm:hover:border-gray-700"
  return (
    <div
      className={`border-l-2 border-transparent pl-3 sm:cursor-pointer ${activeClass} ${
        isLast ? "mb-0" : "mb-8"
      }`}
      onClick={() => {
        setOfficeIndex(index)
      }}
      data-test-id="office-item"
    >
      <p className="text-xl font-bold">{locationName}</p>
      <div className="mt-2 text-sm">
        <p>{phone}</p>
        <p>{address}</p>
      </div>
    </div>
  )
}

const Office = ({ offices }) => {
  const [officeIndex, setOfficeIndex] = useState(0)
  const officeList = offices?.map((office, index) => (
    <OfficeItem
      key={index}
      office={office}
      isActive={officeIndex === index}
      isLast={index === offices.length - 1}
      index={index}
      setOfficeIndex={setOfficeIndex}
    />
  ))
  return (
    <div className="container mx-auto grid px-6 py-12 sm:grid-cols-3 sm:gap-12 sm:px-8 sm:py-24 lg:grid-cols-4">
      <div className="sm:col-span-2 lg:col-span-3">
        <iframe
          className="hidden h-full w-full sm:block"
          data-test-id="google-map"
          src={offices[officeIndex].url}
        />
      </div>
      <div className="text-center text-gray-700 sm:text-left sm:text-gray-500">
        {officeList}
      </div>
    </div>
  )
}

OfficeItem.propTypes = {
  office: PropTypes.object,
  isActive: PropTypes.bool,
  isLast: PropTypes.bool,
  setOfficeIndex: PropTypes.func,
  index: PropTypes.number,
}
Office.propTypes = {
  offices: PropTypes.array,
}

export default Office
